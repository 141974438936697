import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent } from './private/containers';
import { IconModule, IconSetService } from '@coreui/icons-angular';
import { AvatarModule, BadgeModule, BreadcrumbModule, ButtonGroupModule, ButtonModule, CardModule, DropdownModule, FooterModule, FormModule, GridModule, HeaderModule, ListGroupModule, ModalModule, NavModule, ProgressModule, SharedModule, SidebarModule, TabsModule, UtilitiesModule } from '@coreui/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy, registerLocaleData, DatePipe } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { ToastrModule } from 'ngx-toastr';
import { UserService } from './core/auth/user.service';
import { AuthenticationService } from './core/auth/authentication.service';
import { TokenInterceptor } from './core/interceptors/token-interceptor';
import { AuthGuard } from './core/auth/auth.guard';
import { IpService } from './core/auth/ip.service';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';
import { TreeviewModule } from '@ash-mezdo/ngx-treeview';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

import localePtBr from '@angular/common/locales/pt';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

registerLocaleData(localePtBr);

const APP_CONTAINERS = [
  DefaultFooterComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent
];

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent, ...APP_CONTAINERS
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AvatarModule,
    BreadcrumbModule,
    FooterModule,
    DropdownModule,
    GridModule,
    HeaderModule,
    SidebarModule,
    IconModule,
    NavModule,
    ButtonModule,
    FormModule,
    UtilitiesModule,
    ButtonGroupModule,
    ReactiveFormsModule,
    SidebarModule,
    SharedModule.forRoot(),
    TabsModule,
    ListGroupModule,
    ProgressModule,
    BadgeModule,
    ListGroupModule,
    CardModule,
    NgScrollbarModule,
    ModalModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: "rgba(0,0,0,0.1)",
      backdropBorderRadius: "4px",
      primaryColour: "#ffffff",
      secondaryColour: "#ffffff",
      tertiaryColour: "#ffffff",
    }),
    TreeviewModule.forRoot(),
    NgxDropzoneModule,
    NgbModalModule,
    NgxSpinnerModule
  ],
  providers: [
    DatePipe,
    AuthenticationService,
    UserService,
    IpService,
    IconSetService,
    Title,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    provideEnvironmentNgxMask(maskConfig),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt'},
    AuthGuard
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
