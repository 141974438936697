import { AfterViewInit, Component, OnInit } from '@angular/core';

import { navItems } from './_nav';
import { MenuService } from '../../shared/services/menu.service';
import { INavData } from '@coreui/angular/lib/sidebar/sidebar-nav';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { MenuModel } from '../../shared/models/menu/menu.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit, AfterViewInit{

  public navItemsOld = navItems;
  public navItems: INavData[] = [];
  portalName: string = environment.portalName;
  portalLogoUrl: string = environment.portalLogoUrl;
  portalLogoNarrowUrl: string = environment.portalLogoNarrowUrl;

  constructor(
    private _menuService: MenuService,
    private _toastr: ToastrService
  ) {
    
  }
  ngAfterViewInit(): void {
    this.setNavItems();
  }
  ngOnInit(): void {
    // this.setNavItems();
  }

  setRecursiveNavItems(currentItem: MenuModel, currentNav: INavData, level: number, allItems: MenuModel[]): void {
    let childItems = allItems.filter(f => f.parentMenuId == currentItem.id && f.menuType == 1);
    if(childItems) {
      level++;
      currentNav.children = [];
      for (let index = 0; index < childItems.length; index++) {
        const element = childItems[index];

        let elementNav: INavData;

        switch (element.menuType) {
          case 1: // menu
            elementNav = {
              name: element.menuDescription,
              url: element.menuAction ?? "#",
              iconComponent: { name: element.menuIcon ?? "cil-puzzle" }
            };
            break;
          case 2: // separador
            elementNav = {
              divider: true,
              name: element.menuName
            };
            break;
          case 3: // Título
            elementNav = {
              title: true,
              name: element.menuDescription
            };
            break;
        
          default:
            elementNav = {};
            break;
        }

        currentNav.children.push(elementNav);
        this.setRecursiveNavItems(element, elementNav, level, allItems);
      }
    }
  }

  async setNavItems() {
    let menuItems = await this._menuService.GetMenuList().toPromise();
    let parentItems = menuItems?.filter(q => q.parentMenuId == null && q.menuType == 1);
    this.navItems = [];

    if(menuItems && parentItems) {
      for (let index = 0; index < parentItems.length; index++) {
        const element = parentItems[index];

        let elementNav: INavData;

        switch (element.menuType) {
          case 1: // menu
            elementNav = {
              name: element.menuDescription,
              url: element.menuAction ?? "#",
              iconComponent: { name: element.menuIcon ?? "cil-puzzle" }
            };
            break;
          case 2: // separador
            elementNav = {
              divider: true,
              name: element.menuName
            };
            break;
          case 3: // Título
            elementNav = {
              title: true,
              name: element.menuDescription
            };
            break;
        
          default:
            elementNav = {};
            break;
        }

        this.navItems.push(elementNav);    
        this.setRecursiveNavItems(element, elementNav, 1, menuItems);
      }
    }
  }
}
