import { portalData } from "./portal-data";

export const environment = {
    production: false,
    ...portalData,
    authApi: 'https://dev-api.geniusfiscal.com.br/auth',
    customerApi: 'https://dev-api.geniusfiscal.com.br/customer',
    filesApi: 'https://dev-api.geniusfiscal.com.br/files',
    customerFilesApi: 'https://dev-api.geniusfiscal.com.br/customerfiles',
    publicRequestsApi: 'https://dev-api.geniusfiscal.com.br/publicrequests',
    partnerApi: 'https://dev-api.geniusfiscal.com.br/partner',
    opportunityApi: 'https://dev-api.geniusfiscal.com.br/opportunity',
    portalId: '93ccea84-fb39-11ee-8db2-6ef451347a47',
    contactEmail: 'henrique@geniusfiscal.com.br',
    firebase: {
        apiKey: "AIzaSyDhfpSsMVA3RIZQ9I1f_0MNNr3SdWelhXI",
        authDomain: "geniofiscal-dev.firebaseapp.com",
        projectId: "geniofiscal-dev",
        storageBucket: "geniofiscal-dev.appspot.com",
        messagingSenderId: "44460042626",
        appId: "1:44460042626:web:7b0ef9e55fdefb1fdc479b",
        measurementId: "G-32R379NWKE"
    }
    
  };