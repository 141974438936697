import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/core/auth/authentication.service';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit {

  @Input() sidebarId: string = "sidebar";

  public newMessages = new Array(4);
  public newTasks = new Array(5);
  public newNotifications = new Array(5);

  public user: any;
  public userImageUrl?: string;
  public companyName?: string;
  public companyQtt: number = 1;

  constructor(
    private classToggler: ClassToggleService,
    private authService: AuthenticationService,
    private toastr: ToastrService
  ) {
    super();

    this.authService.LoggedInUser()
    .then((result) => {
      this.user = result;

      this.loadUserData();
    })
    .catch((error) => {
      this.user = null;
      this.toastr.error("Erro ao buscar dados do usuário logado!", "Erro!");
    });
  }

  ngOnInit(): void {
    
  }

  logout() {
    this.authService.Logout();
  }

  loadUserData() {
    this.companyName = localStorage.getItem('company.Name') ?? "SEM EMPRESA CADASTRADA";
    this.companyQtt = Number(localStorage.getItem('company.Qtt') ?? 1);
    this.user.name = localStorage.getItem('user.Name');
    this.userImageUrl = localStorage.getItem('user.ImageUrl') ?? 
    `https://ui-avatars.com/api/?name=${localStorage.getItem('user.Name')}`;
  }
}
