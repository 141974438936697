import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuModel } from '../models/menu/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private authApi =  `${environment.authApi}/api/portalmenu`;
  
  constructor(
    private _http: HttpClient
  ) { }

  GetMenuList() {
    return this._http.get<MenuModel[]>(`${this.authApi}`);
  }
}
