import * as CryptoJS from 'crypto-js';

export class Utils {
    constructor() {
        
    }

    private static key = "CHAVEDEDECRYPT";

    static encrypt(value: string) {
        return CryptoJS.AES.encrypt(value.trim(), this.key.trim()).toString();
    }

    static decrypt(value: string) {
        return CryptoJS.AES.decrypt(value.trim(), this.key.trim()).toString(CryptoJS.enc.Utf8);
    }

}