import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SignUpModel } from './models/signup.model';
import { UserService } from './user.service';
import { SignedUpModel } from './models/signedup.model';
import { IpService } from './ip.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/utils/utils';
import { PortalCompanyUserModel } from 'src/app/private/shared/models/user/portal-company-user.model';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  signedUpUser!: SignedUpModel;
  authUser: firebase.default.User | undefined;

  constructor(
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private ipService: IpService,
    private _router: Router
  ) { }
  
  // Sign up with email/password
  SignUpWithEmailPassword(signUpData: SignUpModel) {
    return this.userService.RegisterNewUser(signUpData)
    .then((success) => success)
    .catch((error) => {
      throw new Error( error.error.message ?? error.message);
    });
  }

  // Sign in with email/password
  async SignInWithEmailPassword(email: string, password: string, companyId: string, companyQtt: number, company?: PortalCompanyUserModel) {
    try {
      const result = await this.afAuth
        .signInWithEmailAndPassword(email, password);
      this.authUser = result.user!;

      if (companyId != null) {
        this.SetSelectedCompany(companyId, company!.companyName, companyQtt, company!.companyType);
      } else {
        localStorage.setItem('company.Qtt', "0");
      }

      const accessToken = Utils.encrypt(this.GetAccessToken());
      localStorage.setItem('user.accessToken', accessToken);

      await this.ConfirmLogin();

      this.RedirectToMain(companyId);
      return result;

      
    } catch (error_1) {
      throw this.FirebaseAuthError(error_1);
    }
  }

  async ConfirmLogin(): Promise<void> {
    try {
      var user = await this.userService.ConfirmLogin();

      if (user) {
        if (user?.fullName)
          localStorage.setItem('user.Name', (user?.fullName)!);
        if (user?.email)
          localStorage.setItem('user.Email', (user?.email)!);
        if (user?.imageUrl)
          localStorage.setItem('user.ImageUrl', (user?.imageUrl)!);

      }      
    } catch (error) {
      localStorage.clear();
      throw error;
    }
  }

  async SwitchSelectedCompany(company: PortalCompanyUserModel) {
    try {
      
      const companyQtt = + (localStorage.getItem('company.Qtt') ?? "");
      this.SetSelectedCompany(company.companyId, company!.companyName, companyQtt, company!.companyType);

      await this.ConfirmLogin();

      this.RedirectToMain(company.id);
      
    } catch (error_1) {
      throw this.FirebaseAuthError(error_1);
    }
  }

  ResetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  GetState() {
    return this.afAuth.authState;
  }

  async IsLoggedIn(): Promise<boolean> {

    const state = await this.GetState().toPromise();

    return state?.email != null;
  }

  LoggedInUser() {
    return this.afAuth.currentUser;
  }

  GetAccessToken() {
    if(JSON.stringify(this.afAuth.currentUser) != undefined){
      return JSON.parse(JSON.stringify(this.afAuth.currentUser)).stsTokenManager.accessToken;
    } 
    
    if(JSON.stringify(this.authUser) != undefined) {
      return JSON.parse(JSON.stringify(this.authUser)).stsTokenManager.accessToken; 
    }
    return null;
  }

  Logout() {
    this.afAuth.signOut()
      .then((result) => {

      })
      .catch((error) => {
        throw error;        
      })
      .finally(() => {
        this._router.navigate(['public/login']);
        localStorage.clear();
      })
  }

  SetSelectedCompany(companyId: string, companyName: string, companyQtt: number, companyType: string) {
    localStorage.setItem('company.Id', companyId);
    localStorage.setItem('company.Name', companyName);
    localStorage.setItem('company.Type', companyType);
    localStorage.setItem('company.Qtt', companyQtt.toString());
  }

  RedirectToMain(companyId: string, refresh: boolean = false){
    if(companyId != null) {
      this._router.navigate(['private'])
      .then(
        (res) => {
          if(refresh)
            window.location.reload();
        }
      );
    } else {
      this._router.navigate(['private/company/link']);
    }
  }

  private FirebaseAuthError(error: any): any {

    switch (error.code) {
      case "auth/invalid-login-credentials":
        error.message = "Usuário ou senha inválidos";
        break;
      case "auth/too-many-requests":
        error.message = "Conta bloqueada por excesso de tentativas. Redefina sua senha";
        break;
    
      default:
        break;
    } 

    return error;
  }
}
