import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IpService {
  //private apiUrl = 'https://api.ipify.org?format=json';
  private apiUrl = 'https://ip.guide/';
  //private apiUrl2 = 'https://ifconfig.co/json';

  constructor(private http: HttpClient) { }

  public getIpAddress(): Promise<any> {
    let headers = new HttpHeaders()
            .set('SkipIntercept', 'true');
    // headers = headers.append('Accept', 'application/json');

    return this.http.get(this.apiUrl, {headers: headers}).toPromise();
  }
}