<!--<c-footer>-->
  <div>
    <!-- <a href="https://coreui.io/pro/angular/" target="_blank">{{portalName}}</a> -->
    <span> &copy; {{currentYear}} {{portalName}}</span>
  </div>
  <div *ngIf="environmentDev" class="mx-3">
    <span class="text-danger"> AMBIENTE DE DESENVOLVIMENTO (TESTES)</span>
  </div>
  <div class="ms-auto">
    <!-- Feito com <svg cIcon name="cilHeart"></svg> por -->
    <!-- Powered by -->
    <a [href]="creatorLink" target="_blank">
      <span> {{creatorName}}</span>
    </a>
  </div>
<!--</c-footer>-->
