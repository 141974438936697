import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/utils/utils';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean = false;

  constructor(
    private authService: AuthenticationService, 
    private _router: Router,
    private _toastr: ToastrService
  ) {
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.headers.get('SkipIntercept') && req.headers.get('SkipIntercept') === 'true') {
      const headers = req.headers.delete('SkipIntercept');

      return next.handle(
        req.clone({ headers })
      );
    } 
    
    if (req.headers.get('PublicRequest') && req.headers.get('PublicRequest') === 'true') {
      let headers = req.headers.delete('PublicRequest');

      headers = headers.append("PortalId", environment.portalId);

      return next.handle(
        req.clone({ headers })
      );
    } else {     
      const encryptedToken = localStorage.getItem('user.accessToken');
      const token = Utils.decrypt(encryptedToken!);
      const companyId = localStorage.getItem('company.Id');
      const companyType = localStorage.getItem('company.Type');

      let headers = {};

      if(companyId) {
        headers = {
          Authorization: 'Bearer ' + token,
          PortalId: environment.portalId,
          CompanyId: companyId!,
          CompanyType: companyType!
        };
      } else {
        headers = {
          Authorization: 'Bearer ' + token,
          PortalId: environment.portalId
        };
      }
  

      return next.handle(
        req.clone({
          setHeaders: headers
        })
      ).pipe(
        tap(
          (event: HttpEvent<any>) => { },
          (err: HttpErrorResponse) => {
            if (err.status == 401) {
              this._toastr.error('Redirecionando para o login.','Você foi desconectado!')
              this._router.navigate(['/', 'public','login']);
            }
          }
        )
      );
    }
  }

  public getToken() {
    const token = this.authService.GetAccessToken();
    return token;
  }
}