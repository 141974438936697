import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { SignUpModel } from "./models/signup.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DatabaseUser } from "./models/databaseuser.model";
import { SignedUpModel } from "./models/signedup.model";
import { IpService } from "./ip.service";
import { PortalCompanyUserModel } from "../../private/shared/models/user/portal-company-user.model";
import { DeviceDetectorService } from "ngx-device-detector";
import { UserProfileModel } from "src/app/private/shared/models/user/user-profile.model";

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(
        private http: HttpClient,
        private ipService: IpService,
        private deviceService: DeviceDetectorService
    ) {}

    private userApi =  `${environment.authApi}/api/login`;

    VerifyUser(user: string) {
        const headers = new HttpHeaders()
            .set('PublicRequest', 'true');
        return this.http.get<PortalCompanyUserModel[]>(`${this.userApi}/verify-user`, {
            headers: headers,
            params: {"userEmail": user}
        }).toPromise();
    }

    RegisterNewUser(user: SignUpModel) {
        const headers = new HttpHeaders()
            .set('PublicRequest', 'true');
        return this.http.post<DatabaseUser>(`${this.userApi}`, user, {headers: headers}).toPromise();
    }

     async ConfirmLogin() {

        let ipSearch = await this.ipService.getIpAddress();
        let device = this.deviceService;

        let body = {};

        if (ipSearch && ipSearch.ip != undefined) {
            body = {
                "ip": ipSearch.ip,
                "city": ipSearch.location?.city,
                "country": ipSearch.location?.country,
                "location": `${ipSearch.location?.latitude} ${ipSearch.location?.longitude}`,
                "networkOrganization": ipSearch.network.autonomous_system.organization,
                "device": device.device,
                "deviceType": device.deviceType,
                "os": device.os,
                "osVersion": device.os_version,
                "browser": device.browser,
                "browserVersion": device.browser_version,
                "userAgent": device.userAgent
            };
        }     

        return this.http.post<UserProfileModel>(`${this.userApi}/confirm-login`, body).toPromise();
    }
}