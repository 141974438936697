import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _authService: AuthenticationService, private _router: Router) {
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.authorize();
    }

    authorize(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._authService.GetState().subscribe(
                {
                    next: (result) => {
                        if(result?.email != null) {
                            resolve(true);
                        } else {
                            this._router.navigate(["public/login"]);
                            resolve(false);
                        }
                    },
                    error(err) {
                    }
                }
            );
        });
    }

}